import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import classNames from 'classnames';

export class AppraiseByTabsHeaderItem extends PureComponent {
  static propTypes = {
    tabId: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onBtnClick: PropTypes.func.isRequired,
    isActive: PropTypes.bool,
    isFirstItem: PropTypes.bool,
    isAppraisalValuePage: PropTypes.bool,
    isBackgroundStyleTabEnabled: PropTypes.bool,
    renderAddressTab: PropTypes.bool,
  };

  static defaultProps = {
    isActive: false,
    isFirstItem: false,
    isAppraisalValuePage: false,
    isBackgroundStyleTabEnabled: false,
    renderAddressTab: false,
  };

  handleClick = () => {
    this.props.onBtnClick(this.props.tabId);
  };

  render() {
    const {
      isActive,
      label,
      tabId,
      isFirstItem,
      isAppraisalValuePage,
      isBackgroundStyleTabEnabled,
      renderAddressTab,
    } = this.props;
    const firstItemPaddingClass = isAppraisalValuePage
      ? {
          'px-0_5': !isFirstItem,
          'pl-0 pr-0_5': isFirstItem,
        }
      : {
          'px-0_5 px-md-0_75': !isFirstItem && !isBackgroundStyleTabEnabled,
          'pl-0 pr-0_5 pr-md-0_75': isFirstItem && !isBackgroundStyleTabEnabled,
          'm-0': isBackgroundStyleTabEnabled,
          'pt-0_75': isBackgroundStyleTabEnabled,
          'px-1': isBackgroundStyleTabEnabled && !renderAddressTab,
          'px-0_75 px-sm-1_5 px-md-2': renderAddressTab,
          'rounded-left': isFirstItem && isBackgroundStyleTabEnabled,
          'pb-0': isActive && isBackgroundStyleTabEnabled,
        };

    return (
      <Button
        role="tab"
        color={isBackgroundStyleTabEnabled ? 'custom' : undefined}
        className={classNames(
          'appraise-by-tab-control size-16 pt-0 text-gray-darker text-decoration-none pb-0_5',
          `tab-${tabId.toLowerCase()}`,
          {
            active: isActive,
            'text-primary-darker': isActive && isBackgroundStyleTabEnabled,
            'bg-gray-lightest': !isActive && isBackgroundStyleTabEnabled,
            'border-0': !isBackgroundStyleTabEnabled,
            ...firstItemPaddingClass,
          }
        )}
        onClick={this.handleClick}
        data-testid={`tab-${tabId}`}
        data-tracking-id="appraisal_tab_selected"
        data-tracking-value={label}
        data-no-refresh
        aria-selected={isActive}
      >
        <span
          className={classNames('appraise-by-tab-label font-weight-normal text-capitalize', {
            'pb-0_5': isBackgroundStyleTabEnabled,
          })}
        >
          {label}
        </span>
      </Button>
    );
  }
}
