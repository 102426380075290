import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get, noop, isEqual, mapValues } from 'lodash';
import { Button, ButtonGroup, TabPane, TabContent, Row, Col } from 'reactstrap';

// Models
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { MultiOfferModel, searchByVinPath } from 'client/data/models/multi-offer';

// Utils
import { getQuery } from 'client/utils/location';
import { EventToolbox } from 'client/utils/event-toolbox';
import { transformVin } from 'site-modules/shared/utils/appraiser/appraiser-validation-helper';
import { fireQuoteRecencyTrackingPixel } from 'site-modules/shared/utils/appraisal/common-utils';
import { delayHeavyTask } from 'client/site-modules/shared/utils/inp-utils';
import { Storage } from 'site-modules/shared/utils/storage';

// Constants
import {
  APPRAISE_BY_TAB_HEADERS_CLASSNAME,
  APPRAISAL_QUERY_TAB_LICENSE_PLATE,
  VALID_APPRAISAL_QUERY_TAB_VALUES,
} from 'site-modules/shared/components/appraisal/appraisal-tabs/constants';
import {
  APPRAISAL_VEHICLE_ENTRY_CREATIVE_ID,
  APPRAISAL_VEHICLE_ENTRY_VIN_LOOKUP_CREATIVE_ID,
  PARTNER_OFFER_ADDRESS_LOOKUP,
} from 'client/site-modules/shared/constants/appraisal/appraisal';
import { TrackingConstant } from 'client/tracking/constant';
import {
  MMY_TAB_ID,
  VIN_TAB_ID,
  LICENSE_PLATE_TAB_ID,
  ADDRESS_TAB_ID,
  MMY_TAB,
  VIN_TAB,
  LICENSE_PLATE_TAB,
  ADDRESS_TAB,
  TABS,
  TAB_LINKS_ORDER_BY,
} from 'site-modules/shared/components/appraisal/appraisal-tabs-container/appraisal-tab-constants';
import { LP_AND_VIN_STEP_STORAGE_KEYS } from 'site-modules/shared/components/appraisal/trade-in-appraiser-steps/license-plate-and-vin-step/constants';

// Components
import { AppraiseByTabsHeaderItem } from 'site-modules/shared/components/appraisal/appraisal-tabs/appraise-by-tabs-header-item';
import { AppraiseByMmyTabContainer } from 'site-modules/shared/components/appraisal/appraisal-tabs/appraise-by-mmy-tab-container';
import { VinTabContainer } from 'site-modules/shared/components/appraisal/appraisal-tabs/vin-tab/vin-tab-container';
import { AddressTab } from 'site-modules/shared/components/appraisal/appraisal-tabs/address-tab/address-tab';
import { LicensePlateTabContainer } from 'site-modules/shared/components/appraisal/appraisal-tabs/license-plate-tab/license-plate-tab-container';
import { AppraisalTabLink } from 'site-modules/shared/components/appraisal/appraisal-tabs/appraisal-tab-link/appraisal-tab-link';
import { AppraisalUtmStorage } from 'site-modules/shared/components/appraisal/appraisal-utm-storage/appraisal-utm-storage';
import { AppraisalFindVinDrawer } from 'site-modules/shared/components/find-vin-modal/appraisal-find-vin-drawer';
import { FindVinModal } from 'site-modules/shared/components/find-vin-modal/find-vin-modal';

import './appraisal-tabs.scss';

const MMY_TAB_WITH_COMPONENT = {
  ...MMY_TAB,
  TabComponent: AppraiseByMmyTabContainer,
};
const VIN_TAB_WITH_COMPONENT = {
  ...VIN_TAB,
  TabComponent: VinTabContainer,
};
const LICENSE_PLATE_TAB_WITH_COMPONENT = {
  ...LICENSE_PLATE_TAB,
  TabComponent: LicensePlateTabContainer,
};
const ADDRESS_TAB_WITH_COMPONENT = {
  ...ADDRESS_TAB,
  TabComponent: AddressTab,
};
const TABS_WITH_COMPONENT_BY_ID = {
  [MMY_TAB_ID]: MMY_TAB_WITH_COMPONENT,
  [VIN_TAB_ID]: VIN_TAB_WITH_COMPONENT,
  [LICENSE_PLATE_TAB_ID]: LICENSE_PLATE_TAB_WITH_COMPONENT,
};

const TABS_WITH_COMPONENT = mapValues(TABS, tabs => tabs.map(({ id }) => TABS_WITH_COMPONENT_BY_ID[id]));

const TAB_LINKS_ORDER_BY_WITH_COMPONENT = mapValues(TAB_LINKS_ORDER_BY, ({ FIRST, SECOND }) => ({
  FIRST: TABS_WITH_COMPONENT_BY_ID[FIRST.id],
  SECOND: TABS_WITH_COMPONENT_BY_ID[SECOND.id],
}));

const getActiveTabId = ({
  hasVinQuery,
  isLicensePlateTabDefault,
  hasMMYQuery,
  isLookUpStyleView,
  initialActiveTabId,
}) => {
  if (isLookUpStyleView) return initialActiveTabId;
  if (hasVinQuery) return VIN_TAB_ID;

  return isLicensePlateTabDefault && !hasMMYQuery ? LICENSE_PLATE_TAB_ID : MMY_TAB_ID;
};

const VIN_LOOKUP_BTN_CTA = 'VIN Lookup';

export class AppraisalTabsUI extends Component {
  static propTypes = {
    isMobile: PropTypes.bool,
    isLookUpStyleView: PropTypes.bool,
    isSellCar: PropTypes.bool,
    creativeId: PropTypes.string,
    ctaText: PropTypes.string,
    ctaClassName: PropTypes.string,
    // eslint-disable-next-line react/no-unused-prop-types
    isLicensePlateTabDefault: PropTypes.bool,
    location: PropTypes.shape({}),
    // eslint-disable-next-line react/no-unused-prop-types
    referer: PropTypes.string,
    className: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    // eslint-disable-next-line react/no-unused-prop-types
    initialActiveTabId: PropTypes.string,
    isVdpEmbedded: PropTypes.bool,
    isYearDropdownFirst: PropTypes.bool,
    initialStateCode: PropTypes.string,
    initialVehicle: PropTypes.shape({
      make: PropTypes.string,
      model: PropTypes.string,
      year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    setStylesAndVin: PropTypes.func,
    isFullWidth: PropTypes.bool,
    isVisibleInDrawer: PropTypes.bool,
    isProfileOfferVinPrepopulationEnabled: PropTypes.bool,
    profileOfferVin: PropTypes.string,
    hideEdmundsLogo: PropTypes.bool,
    hasAddressToVinDrawer: PropTypes.bool,
    isBackgroundStyleTabEnabled: PropTypes.bool,
    wrapperClassName: PropTypes.string,
    isEmoSearchEnabled: PropTypes.bool,
    isSameHeight: PropTypes.bool,
    vinModsData: PropTypes.shape({}),
    withContainerPadding: PropTypes.bool,
    isAppraisalLanding: PropTypes.bool,
    isSellCarLanding: PropTypes.bool,
    isSellInCityState: PropTypes.bool,
    linksColorClassName: PropTypes.string,
    accentColorClassName: PropTypes.string,
    accentBgColorClassName: PropTypes.string,
    isWIDG1125chal1: PropTypes.bool,
    isProjectEveStyle: PropTypes.bool,
    renderAddressTab: PropTypes.bool,
    setMmy: PropTypes.func.isRequired,
    setStyleIds: PropTypes.func.isRequired,
    setIsReadyForRedirect: PropTypes.func.isRequired,
    setVin: PropTypes.func.isRequired,
    isEmo2581Chal1: PropTypes.bool,
    isEmo2581Chal2: PropTypes.bool,
  };

  static defaultProps = {
    isMobile: false,
    isLookUpStyleView: false,
    isSellCar: false,
    isVdpEmbedded: false,
    isYearDropdownFirst: true,
    creativeId: APPRAISAL_VEHICLE_ENTRY_CREATIVE_ID,
    ctaText: "See your car's value",
    ctaClassName: undefined,
    isLicensePlateTabDefault: false,
    location: {},
    referer: undefined,
    title: '',
    subtitle: '',
    initialStateCode: '',
    className: '',
    initialActiveTabId: LICENSE_PLATE_TAB_ID,
    initialVehicle: null,
    setStylesAndVin: noop,
    isFullWidth: false,
    isVisibleInDrawer: false,
    isProfileOfferVinPrepopulationEnabled: false,
    profileOfferVin: '',
    hideEdmundsLogo: false,
    isAppExtensionPage: false,
    hasAddressToVinDrawer: false,
    isBackgroundStyleTabEnabled: false,
    wrapperClassName: '',
    isEmoSearchEnabled: false,
    isSameHeight: true,
    vinModsData: null,
    withContainerPadding: true,
    isAppraisalLanding: false,
    isSellCarLanding: false,
    isSellInCityState: false,
    linksColorClassName: 'text-primary-darker',
    accentColorClassName: 'text-primary-darker',
    accentBgColorClassName: 'bg-primary-opacity-2',
    isWIDG1125chal1: false,
    isProjectEveStyle: false,
    renderAddressTab: false,
    isEmo2581Chal1: false,
    isEmo2581Chal2: false,
  };

  constructor(props) {
    super(props);
    this.state = this.buildTabsFromProps();
    AppraisalUtmStorage.checkAndSetSemCampaignAppraisals(this.props.location);
    AppraisalUtmStorage.checkAndSetSemCampaignFacebook(this.props.location);
  }

  componentDidMount() {
    const { tabs, activeTabId } = this.state;
    const { isLookUpStyleView } = this.props;

    if (!isLookUpStyleView) {
      this.fireTabTracking(tabs, activeTabId);
    }

    window.addEventListener('beforeunload', this.clearStorage);
  }

  componentDidUpdate(prevProps) {
    const { tabs, activeTabId } = this.state;
    const { isVisibleInDrawer, isProfileOfferVinPrepopulationEnabled, profileOfferVin, vinModsData } = this.props;

    if (isVisibleInDrawer && !isEqual(prevProps.isVisibleInDrawer, isVisibleInDrawer)) {
      this.fireTabTracking(tabs, activeTabId);
    }

    if (isProfileOfferVinPrepopulationEnabled && prevProps.profileOfferVin !== profileOfferVin && profileOfferVin) {
      this.setActiveTab(VIN_TAB_ID);
    }

    if (!isEqual(vinModsData, prevProps.vinModsData)) {
      fireQuoteRecencyTrackingPixel(get(vinModsData, 'kmx.offersFinalizedAt'));
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.clearStorage);
  }

  onToggleTabChange = (e, tabId) => this.setActiveTab(tabId);

  /**
   * Toggles selected tab.
   * @param {string} tabId
   */
  setActiveTab = tabId =>
    delayHeavyTask(() => {
      this.setState({
        activeTabId: tabId,
      });
    });

  getEdmundsLogo = () => (
    <div>
      <div className="d-inline-flex p-0_25 align-items-center text-primary mb-0_25 bg-transparent size-16 font-weight-normal">
        <i className="icon-edmunds-car size-30 mr-0_25" aria-hidden />
        <span className="pl-0_5">Did you know?</span>
      </div>
    </div>
  );

  storage = new Storage('sessionStorage');

  clearStorage = () => {
    this.storage.remove(LP_AND_VIN_STEP_STORAGE_KEYS.LICENSE_PLATE);
    this.storage.remove(LP_AND_VIN_STEP_STORAGE_KEYS.STATE_CODE);
    this.storage.remove(LP_AND_VIN_STEP_STORAGE_KEYS.VIN);
  };

  buildTabsFromProps = () => {
    const {
      location,
      isSellCar,
      referer,
      isLicensePlateTabDefault,
      initialActiveTabId,
      isLookUpStyleView,
      renderAddressTab,
    } = this.props;
    const { make, model, year, vin, tab } = getQuery(location);
    const hasMMYQuery = make && model && year; // Only used by Sell Car page: See EVAL-2352
    const hasVinQuery = !!vin;
    let appraisalQueryTabUpperCase = tab && tab.toUpperCase();
    if (!VALID_APPRAISAL_QUERY_TAB_VALUES.includes(appraisalQueryTabUpperCase)) {
      appraisalQueryTabUpperCase = null; // Invalidate value
    }
    const isInternallyReferredAppraisalLandingPage = !isSellCar && referer && referer.includes('edmunds.com');
    const isLpQueryTab = appraisalQueryTabUpperCase === APPRAISAL_QUERY_TAB_LICENSE_PLATE;
    const isNoQueryTab = appraisalQueryTabUpperCase === null;
    const isLicensePlateTabDefaulted =
      isLpQueryTab || (isNoQueryTab && (isLicensePlateTabDefault || isInternallyReferredAppraisalLandingPage));

    let tabs = isLicensePlateTabDefaulted ? TABS_WITH_COMPONENT.LICENSE_PLATE : TABS_WITH_COMPONENT.DEFAULT;

    if (isLookUpStyleView) {
      tabs = !isLicensePlateTabDefault
        ? [...TABS_WITH_COMPONENT.YMM_SUPPRESSED].reverse()
        : TABS_WITH_COMPONENT.YMM_SUPPRESSED;
    }

    if (renderAddressTab) {
      tabs = [...tabs, ADDRESS_TAB_WITH_COMPONENT];
    }

    return {
      addressVin: null,
      tabs,
      prepopulatedVehicle: undefined,
      activeTabId: getActiveTabId({
        isLicensePlateTabDefault: isLicensePlateTabDefaulted,
        hasMMYQuery,
        hasVinQuery,
        isLookUpStyleView,
        initialActiveTabId,
      }),
    };
  };

  fireTabTracking = (tabs, activeTabId) => {
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
        action_cause: TrackingConstant.PAGE_LOAD_CAUSE,
        action_name: TrackingConstant.ACTION_SHOW_CONTENT,
        subaction_name: TrackingConstant.TAB_DEFAULT,
        creative_id: this.props.creativeId,
        value: tabs.find(({ id: tabId }) => tabId === activeTabId).label,
      },
    });
  };

  handleTabLinkClick = event => {
    const tabId = event.currentTarget.dataset.tabId;
    this.setActiveTab(tabId);
  };

  handleAddressVin = (addressVin, hasVinMismatchError, vinStyles, { make, model, year }) => {
    const { setMmy, setStyleIds, setIsReadyForRedirect, setVin } = this.props;

    setVin(transformVin(addressVin));
    setMmy({
      make: get(vinStyles, '[0].makeNiceId', make),
      model: get(vinStyles, '[0].modelNiceId', model),
      year: get(vinStyles, '[0].year', year),
    });
    setStyleIds(vinStyles.map(vinStyle => vinStyle.styleId));
    setIsReadyForRedirect(true);
  };

  handlePrepopulatedVehicle = prepopulatedVehicle => {
    this.setState(() => ({
      prepopulatedVehicle,
    }));
  };

  handleVinLookupBtnClick = () => {
    this.setActiveTab(ADDRESS_TAB_ID);
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_START,
      event_data: {
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        action_name: TrackingConstant.ACTION_TOOL_SELECT,
        subaction_name: TrackingConstant.TAB_SELECT,
        action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
        creative_id: APPRAISAL_VEHICLE_ENTRY_VIN_LOOKUP_CREATIVE_ID,
        value: VIN_LOOKUP_BTN_CTA,
      },
    });
  };

  renderTabContent = ({ isWIDG1125chal1 }) => {
    const {
      isMobile,
      isSellCar,
      creativeId,
      ctaText,
      ctaClassName,
      location,
      isVdpEmbedded,
      isYearDropdownFirst,
      initialStateCode,
      initialVehicle,
      isLookUpStyleView,
      setStylesAndVin,
      isFullWidth,
      isProfileOfferVinPrepopulationEnabled,
      profileOfferVin,
      hasAddressToVinDrawer,
      isBackgroundStyleTabEnabled,
      isEmoSearchEnabled,
      isSellInCityState,
      isAppraisalLanding,
    } = this.props;
    const query = getQuery(location);
    const { tabs, activeTabId } = this.state;

    return (
      <TabContent activeTab={activeTabId}>
        {tabs.map(
          ({ id, TabComponent }) =>
            activeTabId === id ? (
              <TabPane tabId={id} key={`tab-${id}`} data-testid={`tab-${id}`}>
                <TabComponent
                  handlePrepopulatedVehicle={this.handlePrepopulatedVehicle}
                  isMobile={isMobile}
                  isSellCar={isSellCar}
                  isSellInCityState={isSellInCityState}
                  isWIDG1125chal1={isWIDG1125chal1}
                  creativeId={creativeId}
                  ctaText={ctaText}
                  btnClassName={ctaClassName}
                  query={query}
                  isVdpEmbedded={isVdpEmbedded}
                  isYearDropdownFirst={isYearDropdownFirst}
                  initialStateCode={initialStateCode}
                  initialVehicle={initialVehicle}
                  setStylesAndVin={setStylesAndVin}
                  isLookUpStyleView={isLookUpStyleView}
                  isFullWidth={isFullWidth}
                  useUniqId={isLookUpStyleView}
                  hasAddressToVinDrawer={hasAddressToVinDrawer}
                  profileOfferVin={isProfileOfferVinPrepopulationEnabled ? profileOfferVin : undefined}
                  isAppraisalLanding={isAppraisalLanding}
                  storage={this.storage}
                  // For License Plate tab
                  vinTabLink={<AppraisalTabLink onClick={this.handleTabLinkClick} tabLink={VIN_TAB_WITH_COMPONENT} />}
                  addressToVinDrawer={this.renderAddressToVinDrawer(isBackgroundStyleTabEnabled, true)}
                  isBackgroundStyleTabEnabled={isBackgroundStyleTabEnabled}
                  isEmoSearchEnabled={isEmoSearchEnabled}
                  // For address tab
                  activeTabId={activeTabId}
                  updateVinAndContinue={this.handleAddressVin}
                  licensePlateAndVinForm={
                    <LicensePlateTabContainer
                      isMobile={isMobile}
                      creativeId={creativeId}
                      ctaText="Continue"
                      query={query}
                      setStylesAndVin={setStylesAndVin}
                      isFullWidth
                      isAddressTab
                      isVinByAddressOverlay
                    />
                  }
                />
              </TabPane>
            ) : null // Don't render tab content if tab is not active
        )}
      </TabContent>
    );
  };

  renderTabsCopy = isBackgroundStyleTabEnabled => {
    const {
      hasAddressToVinDrawer,
      isEmoSearchEnabled,
      isAppraisalLanding,
      linksColorClassName,
      accentColorClassName,
      accentBgColorClassName,
      isSellCarLanding,
      renderAddressTab,
      isEmo2581Chal1,
    } = this.props;
    const { activeTabId } = this.state;

    return (
      <Fragment>
        <div className="edmunds-tip">
          {isBackgroundStyleTabEnabled && (
            <div
              className={classnames(
                'align-bottom mr-0_5 align-items-center bg-transparent size-14 font-weight-normal text-primary-darker',
                isEmoSearchEnabled && isSellCarLanding ? 'd-flex' : 'd-inline-flex'
              )}
            >
              <i className="icon-edmunds-car mr-0_25 size-16" aria-hidden />
              <span className={classnames('font-weight-medium', accentColorClassName)}>Edmunds Tip</span>
            </div>
          )}
          <span>
            <span className={classnames({ 'text-cool-gray-40': isAppraisalLanding })}>By entering your </span>
            {activeTabId === MMY_TAB_ID ? (
              <AppraisalTabLink
                className={linksColorClassName}
                onClick={this.handleTabLinkClick}
                tabLink={TAB_LINKS_ORDER_BY_WITH_COMPONENT[activeTabId].FIRST}
              />
            ) : (
              <span className={classnames({ 'text-cool-gray-40': isAppraisalLanding })}>
                {TAB_LINKS_ORDER_BY_WITH_COMPONENT[activeTabId].FIRST.label}
              </span>
            )}
            <span className={classnames({ 'text-cool-gray-40': isAppraisalLanding })}>{' or '}</span>
            <AppraisalTabLink
              className={linksColorClassName}
              onClick={this.handleTabLinkClick}
              tabLink={TAB_LINKS_ORDER_BY_WITH_COMPONENT[activeTabId].SECOND}
            />
            <span className={classnames({ 'text-cool-gray-40': isAppraisalLanding })}>
              , you&apos;ll get a more accurate appraisal that may raise your car&apos;s value.
            </span>
          </span>
        </div>

        {!renderAddressTab &&
          (hasAddressToVinDrawer
            ? this.renderAddressToVinDrawer(isBackgroundStyleTabEnabled)
            : isBackgroundStyleTabEnabled && (
                <FindVinModal
                  isBackgroundStyleTabEnabled
                  creativeId={APPRAISAL_VEHICLE_ENTRY_CREATIVE_ID}
                  openCtaAsButtonClassName={classnames(
                    {
                      'ml-lg-0_5 mt-lg-0 d-block': isEmoSearchEnabled,
                    },
                    accentBgColorClassName
                  )}
                  iconClassName={accentColorClassName}
                />
              ))}

        {renderAddressTab && isEmo2581Chal1 && (
          <Button
            color="custom"
            className="bg-blue-100 px-1 py-0_25 rounded-pill size-14 mt-0_5"
            onClick={this.handleVinLookupBtnClick}
          >
            <i className="icon icon-search text-blue-40" aria-hidden />
            <span className="font-weight-normal text-gray-darker ml-0_25">Find my VIN</span>
          </Button>
        )}
      </Fragment>
    );
  };

  renderAddressToVinDrawer = (isBackgroundStyleTabEnabled, displayAsLink = false) => {
    const {
      creativeId,
      isMobile,
      setStylesAndVin,
      location,
      isEmoSearchEnabled,
      accentColorClassName,
      accentBgColorClassName,
      isProjectEveStyle,
      renderAddressTab,
    } = this.props;
    const { activeTabId, prepopulatedVehicle } = this.state;
    const query = getQuery(location);

    if (renderAddressTab) return null;

    return (
      <AppraisalFindVinDrawer
        activeTabId={activeTabId}
        isMobile={isMobile}
        creativeId={PARTNER_OFFER_ADDRESS_LOOKUP}
        updateVinAndContinue={this.handleAddressVin}
        isLandingPage
        isBackgroundStyleTabEnabled={isBackgroundStyleTabEnabled || isProjectEveStyle}
        className={displayAsLink ? 'mb-1 size-16' : 'mt-0_25 size-14'}
        displayAsLink={displayAsLink}
        vehicle={{
          make: { slug: prepopulatedVehicle?.makeSlug },
          model: { slug: prepopulatedVehicle?.modelSlug },
          year: { year: prepopulatedVehicle?.year },
        }}
        openCtaAsButtonClassName={classnames(
          {
            'ml-lg-0_5 mt-lg-0': isEmoSearchEnabled,
          },
          accentBgColorClassName
        )}
        iconClassName={accentColorClassName}
        licensePlateAndVinForm={
          <LicensePlateTabContainer
            isMobile={isMobile}
            creativeId={creativeId}
            ctaText="Continue"
            query={query}
            setStylesAndVin={setStylesAndVin}
            isFullWidth
            isVinByAddressOverlay
          />
        }
      />
    );
  };

  render() {
    const {
      creativeId,
      className,
      title,
      subtitle,
      isLookUpStyleView,
      withContainerPadding,
      hideEdmundsLogo,
      isBackgroundStyleTabEnabled,
      wrapperClassName,
      isEmoSearchEnabled,
      isSameHeight,
      isSellCarLanding,
      isWIDG1125chal1,
      renderAddressTab,
      isEmo2581Chal1,
      isEmo2581Chal2,
    } = this.props;
    const { tabs, activeTabId } = this.state;
    const isAddressTabActive = activeTabId === ADDRESS_TAB_ID;

    return (
      <div
        className={classnames('appraisal-tabs-container-wrapper', wrapperClassName, {
          'appraisal-tabs-container-wrapper-background-style-enabled': isBackgroundStyleTabEnabled,
          'is-same-height': isSameHeight,
          'emo-2581': isEmo2581Chal1 || isEmo2581Chal2,
        })}
        data-tracking-parent={creativeId}
      >
        {isBackgroundStyleTabEnabled && (
          <ButtonGroup size="md" role="tablist" className="tab-list">
            {tabs.map(({ id, label }, index) => (
              <AppraiseByTabsHeaderItem
                key={id}
                label={label}
                tabId={id}
                isActive={id === activeTabId}
                onBtnClick={this.setActiveTab}
                isFirstItem={index === 0}
                isBackgroundStyleTabEnabled
                renderAddressTab={renderAddressTab}
              />
            ))}
          </ButtonGroup>
        )}
        <div
          id="appraisal-tabs-container"
          className={classnames('appraisal-tabs-container bg-white', className, {
            rounded: !isLookUpStyleView && withContainerPadding,
            'is-emo-search-sell-car-landing': isEmoSearchEnabled && isSellCarLanding,
            'pb-lg-0_5': isSellCarLanding,
          })}
          role={isBackgroundStyleTabEnabled ? 'tabpanel' : undefined}
        >
          <div
            className={classnames({
              'p-1 p-md-1_5': !isLookUpStyleView && withContainerPadding,
            })}
          >
            {title && (
              <div className={classnames('heading-4', { 'mb-1_5': !isEmoSearchEnabled, 'mb-0_5': isEmoSearchEnabled })}>
                {title}
              </div>
            )}
            {subtitle && <div className="mb-1">{subtitle}</div>}
            <div
              className={classnames(`d-flex ${APPRAISE_BY_TAB_HEADERS_CLASSNAME}`, {
                'mb-1 mb-lg-1_5': isLookUpStyleView,
              })}
            >
              {isLookUpStyleView && (
                <span className="medium text-gray-darker font-weight-bold d-inline-block pb-0_5 mr-0_5">Enter by:</span>
              )}
              {!isBackgroundStyleTabEnabled && (
                <ButtonGroup size="md" role="tablist">
                  {tabs.map(({ id, label }, index) => (
                    <AppraiseByTabsHeaderItem
                      key={id}
                      label={label}
                      tabId={id}
                      isActive={id === activeTabId}
                      onBtnClick={this.setActiveTab}
                      isFirstItem={index === 0}
                    />
                  ))}
                </ButtonGroup>
              )}
            </div>
            {!isEmoSearchEnabled && isBackgroundStyleTabEnabled && !isAddressTabActive && (
              <div className="appraisal-tab-link-copy text-gray-dark size-14 mb-1_5">{this.renderTabsCopy(true)}</div>
            )}
            {!isLookUpStyleView && !isBackgroundStyleTabEnabled && !isAddressTabActive ? (
              <Row>
                <Col xs={12} className="appraisal-tab-link-copy text-gray-dark size-14 mt-1 mb-1_5">
                  {!hideEdmundsLogo && this.getEdmundsLogo()}
                  {this.renderTabsCopy(true)}
                </Col>
              </Row>
            ) : null}
            {this.renderTabContent({ isWIDG1125chal1 })}
            {isEmoSearchEnabled && isBackgroundStyleTabEnabled && !isAddressTabActive && (
              <Row>
                <Col xs={12} className="appraisal-tab-link-copy text-gray-darker size-14 mt-1">
                  <div
                    className={classnames(
                      'size-14 mb-1_5 d-lg-flex',
                      isSellCarLanding ? 'align-items-end' : 'align-items-center'
                    )}
                  >
                    {this.renderTabsCopy(true)}
                  </div>
                </Col>
              </Row>
            )}
          </div>
          {renderAddressTab && [MMY_TAB_ID, VIN_TAB_ID, LICENSE_PLATE_TAB_ID].includes(activeTabId) && isEmo2581Chal2 && (
            <div className="px-1 px-md-1_5">
              <div className="d-flex justify-content-center align-items-center mb-1">
                <div className="horizontal-line bg-cool-gray-60 w-100" />
                <span className="mx-0_5">or</span>
                <div className="horizontal-line bg-cool-gray-60 w-100" />
              </div>
              <Button
                color="success"
                className={'text-transform-none cta-btn d-flex align-items-center justify-content-center w-100'}
                onClick={this.handleVinLookupBtnClick}
                outline
              >
                <i className="icon-search mr-0_5" aria-hidden />
                <span className="size-16 text-green-40">{VIN_LOOKUP_BTN_CTA}</span>
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export const stateToPropsConfig = {
  vinModsData: bindToPath(({ vin }) => (vin ? searchByVinPath.build(vin) : null), MultiOfferModel),
};

export const AppraisalTabs = connectToModel(AppraisalTabsUI, stateToPropsConfig);
